import "/resources/js/bootstrap.js";

import { createApp } from "/node_modules/.vite/deps/vue.js?v=e42b3d3b";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=e42b3d3b";
import { createToast } from "/node_modules/.vite/deps/mosha-vue-toastify.js?v=e42b3d3b";
import { i18n } from "/resources/js/i18n.js"
import greetings from "/resources/js/greetings.js"

window.app = createApp({});


window.app.use(i18n);

window.app.use(createPinia());

import "/resources/js/websocket.js";



window.addEventListener('load', function() {
    window.app.mount('#app');
    window.hidePreloader();
    greetings();
    setTimeout(() => {
        for(let type of Object.keys(window.alerts)) {
            if (window.alerts[type]) {
                createToast(window.alerts[type], {
                    type: type,
                    showIcon: true,
                    timeout: 5_000,
                    transition: 'slide',
                })
            }
        }
    }, 500)
});
